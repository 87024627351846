<template>
  <div class="d-flex align-items-left" style="width: 100%">
    <div class="slash" :style="{ backgroundColor: getHex() }"></div>
    <div class="parallelogramn">
      <div
        class="text text-center"
        :style="{ fontSize: this.fontSize }"
        @click="redirect()"
      >
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: String,
    text: String,
    link: String,
    target: String,
    width: {
      default: "300px",
      type: String,
    },
    fontSize: {
      default: "20px",
      type: String,
    },
  },
  methods: {
    getHex() {
      switch (this.color) {
        case "green":
          return "#9ACA33";
        case "orange":
          return "#FC9728";
        default:
          return this.color;
      }
    },
    redirect() {
      if (!this.link) {
        return;
      }

      if (this.link.match(/^https?:\/\//)) {
        window.open(this.link, this.target);
        return;
      }

      this.$router.push(this.link);
    },
  },
};
</script>

<style lang="sass">
.label
  -webkit-transform: skew(-20deg)
  -moz-transform: skew(-20deg)
  -ms-transform: skew(-20deg)
  -o-transform: skew(-20deg)
  transform: skew(-20deg)
  .parallelogramn
    background: linear-gradient(90deg, rgba(33,78,136,1) 0%, rgba(20,0,52,1) 100%)
    width: 90%
  .slash
    height: 100%
    width: 14px
.text
  color: #fff
  font-weight: bolder
  font-size: x-large
  -webkit-transform: skew(20deg)
  -moz-transform: skew(20deg)
  -ms-transform: skew(20deg)
  -o-transform: skew(20deg)
  transform: skew(20deg)
  padding: 20px
</style>
