<template>
  <main class="container exhibitors-container my-40">
    <div class="row">
      <div class="col-md-12 mb-60">
        <div class="row d-flex justify-content-center">
          <div
            class="col-md-6 label d-flex justify-content-center"
            style="cursor: pointer"
          >
            <Label
              color="orange"
              text="INSCRIPCIONES AQUÍ"
              link="http://intranetredes.derecho.uc.cl/ferias/inscripcion"
              target="_blank"
            />
          </div>
        </div>
      </div>
      <div class="col-md-12 mb-40">
        <div class="d-flex align-items-center mb-20">
          <h3>¿QUÉ IMPLICA SER EXPOSITOR?</h3>
          <span class="uc-heading-decoration"></span>
        </div>
        <p class="mt-5">
          Cada expositor tendrá un stand físico y uno virtual en cual podrá:
        </p>
      </div>
      <div class="col-md-6 mb-20">
        <div class="d-flex align-items-center mb-16">
          <h5>Stand presencial</h5>
        </div>
        <ul>
          <li>
            Interactuar con los participantes
            <i
              v-tooltip="tooltip.interaction"
              class="uc-icon"
              style="cursor: pointer"
              >info</i
            >
          </li>
          <li>
            Merchandising
            <i
              v-tooltip="tooltip.merchandising"
              class="uc-icon"
              style="cursor: pointer"
              >info</i
            >
          </li>
          <li>
            Realizar entrevistas programadas
            <i
              v-tooltip="tooltip.scheduleInterview"
              class="uc-icon"
              style="cursor: pointer"
              >info</i
            >
          </li>
          <li>
            Realizar entrevistas espontáneas
            <i
              v-tooltip="tooltip.spontaneousInterview"
              class="uc-icon"
              style="cursor: pointer"
              >info</i
            >
          </li>
        </ul>
      </div>
      <div class="col-md-6 mb-40">
        <div class="d-flex align-items-center mb-16">
          <h5>Stand virtual</h5>
        </div>
        <ul>
          <li>
            Video Institucional
            <i v-tooltip="tooltip.video" class="uc-icon" style="cursor: pointer"
              >info</i
            >
          </li>
          <li>
            Reseña
            <i
              v-tooltip="tooltip.review"
              class="uc-icon"
              style="cursor: pointer"
              >info</i
            >
          </li>
          <li>
            Información sobre Pasantías
            <i
              v-tooltip="tooltip.internship"
              class="uc-icon"
              style="cursor: pointer"
              >info</i
            >
          </li>
          <li>
            Currículums
            <i
              v-tooltip="tooltip.resume"
              class="uc-icon"
              style="cursor: pointer"
              >info</i
            >
          </li>
          <li>
            Página Oficial y Redes Sociales de los Expositores
            <i
              v-tooltip="tooltip.social"
              class="uc-icon"
              style="cursor: pointer"
              >info</i
            >
          </li>
        </ul>
      </div>

      <div class="col-md-12 mb-20">
        <div class="d-flex align-items-center">
          <h3>Charlas Presenciales</h3>
          <span class="uc-heading-decoration"></span>
        </div>
      </div>
      <div class="col-md-12 mb-40">
        <p>
          Tendrán la opción de participar como panelista en los webinars. Estos
          serán temas pre establecidos y tendrán una duración entre 20 y 30
          minutos dependiento de el número de panelistas inscritos.
        </p>
      </div>

      <div class="col-md-4 mb-40">
        <div class="d-flex align-items-center mb-20">
          <h3>PUBLICA TU VIDEO</h3>
          <span class="uc-heading-decoration"></span>
        </div>
        <ul>
          <li>Revisa el Tutorial para que prepares tu video</li>
          <li>
            Graba tu video y súbelo en Redes Derecho UC (previa inscripción, en
            formato
            <kbd>mp4</kbd> con un máximo de 3 minutos)
          </li>
          <li>El video será publicado el día de la Feria</li>
        </ul>
      </div>

      <div class="col-md-8 mb-40 text-center">
        <div class="tutorial-video embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            src="https://www.youtube.com/embed/4iOlrMpLCu0"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div class="col-md-12 mb-20">
        <div class="d-flex align-items-center">
          <h3>¿CÓMO PAGAR?</h3>
          <span class="uc-heading-decoration"></span>
        </div>
      </div>
      <div class="col-md-12 mb-40">
        <p>
          El proceso de pago para ser expositor se debe hacer a través de la
          Plataforma Redes Derecho UC donde encontrara las formas de pago y toda
          la información al respecto.
        </p>
      </div>
    </div>
  </main>
</template>

<script>
import Label from "@/components/Label.vue";
import VTooltip from "v-tooltip";
import Vue from "vue";

Vue.use(VTooltip);

export default {
  components: {
    Label,
  },
  data() {
    return {
      tooltip: {
        interaction:
          "Dar a conocer información de su institución, áreas de ejercicio, y resolver dudas y consultas de los participantes",
        merchandising:
          "Posibilidad de entregar  material informativo, regalos o material de publicidad",
        scheduleInterview:
          "Realizar entrevistas programadas mediante un proceso previo de selección de candidatos cuyo objetivo es entregarle a cada expositor la posibilidad llegar a los candidatos con el perfil que requieren",
        spontaneousInterview:
          "Tener la opción de entrevistar a los participantes que se acerquen a su stand espontáneamente",
        video:
          "Cada expositor puede subir un video para presentar su institución, en este pueden mostrar sus actividades generales, testimonios de sus integrantes, principios inspiradores, imágenes corporativas, entre otros. Dicho video se transmitirá el día de la Feria en el canal principal y además estará disponible en el stand virtual de cada institución",
        review:
          "Una reseña escrita para que el expositor señale la información más relevante de su institución, incluyendo por ejemplo sus premios y/o reconocimientos",
        internship:
          "En cada stand existirá una casilla habilitada para que los asistentes a la feria completen un formulario tipo CV",
        resume:
          "Igualmente, a fin de suplir las entrevistas espontáneas que se producían en la modalidad presencial, en el stand existirá una casilla habilitada para que todos los asistentes a la Feria puedan dejar su CV a disposición de la institución respectiva",
        chat: "Con el objeto de generar instancias dinámicas de interacción entre expositores y postulantes, cada stand tendrá una chat interactivo por medio del cual se podrá establecer comunicación directa para resolver dudas, efectuar consultas, entre otros",
        social:
          "Finalmente, el stand de cada institución expositora dispondrá de una serie de botones que permitirán redireccionar tanto a su página web oficial como a sus diversas cuentas de redes sociales",
        stand:
          "Cada expositor contará con un stand informativo presencial que se ubicará en el patio de Derecho del 20 al 26 de abril. Cada stand tendrá la información del expositor incluyendo; nombre del estudio, logo, reseña, redes sociales, página web del estudio  y código QR que los llevará al stand virtual de cada expositor de la Feria",
      },
    };
  },
};
</script>

<style lang="sass">
.tutorial-video
  display: inline-block
  width: 50%

@media (max-width: 992px)
  .tutorial-video
    width: 100%

.exhibitors-container ul
  list-style: none

.exhibitors-container ul li::before
  content: "\2022"
  color: #FC9728
  font-weight: bold
  display: inline-block
  width: 1em
  margin-left: -1em
</style>
